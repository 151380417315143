import React, { createContext, useEffect, useMemo } from 'react';
import { ACCESS_DENIED_PAGE_URL } from '../constants/pageUrls';
import { useNavigate } from 'react-router-dom';
import { changeLanguage } from 'i18next';
import { useGetUserMyInfo } from '../api';

type AuthProviderType = {
  userInfo: { email: string; fullName: string; tenantId: string; userId: string; language: string };
};

export const AuthContext = createContext<AuthProviderType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const { data: userInfo } = useGetUserMyInfo();
  changeLanguage(userInfo?.localeCode ?? 'en');

  useEffect(() => {
    if (!document.referrer && !navigator.userAgent.includes('Electron')) {
      return navigate(ACCESS_DENIED_PAGE_URL, { replace: true });
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      userInfo,
    }),
    [userInfo],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

import { USER_ME_API } from '../constants/apiUrl';
import axiosInstance from '../AxiosInstance';
import { useQuery } from '@tanstack/react-query';

export const getUserMyInfo = async () => {
  return await axiosInstance
    .get(USER_ME_API)
    .then((res) => res.data.data)
    .catch((error) => {
      console.error('Failed to fetch user information:', error);
      return { data: {} };
    });
};
export const useGetUserMyInfo = () => {
  return useQuery(['my-info'], () => getUserMyInfo(), { suspense: true });
};

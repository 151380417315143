import axiosInstance from '../AxiosInstance';
import { ADD_SEATS_API, PLAN_SUBSCRIPTION_UPGRADE_API } from '../constants/apiUrl';
import { useQuery } from '@tanstack/react-query';

const upgradeSubscriptionPlan = async (data: { planId: string }) => {
  return await axiosInstance
    .post<{ success: boolean; message: string; data: { widgetUrl: string } }>(PLAN_SUBSCRIPTION_UPGRADE_API, data)
    .then((res) => res.data);
};

export const useUpgradeSubscriptionPlan = (data: { planId: string }) => {
  return useQuery(['upgrade-plan'], () => upgradeSubscriptionPlan(data), {
    enabled: !!data.planId,
    retry: 3,
  });
};

const postAddSeats = async (additionalSeatsCount: number | null) => {
  return await axiosInstance
    .post<{ success: boolean; message: string; data: { widgetUrl: string } }>(ADD_SEATS_API, {
      count: additionalSeatsCount,
    })
    .then((res) => res.data);
};

export const usePostAddSeatsQuery = (count: number | null) => {
  return useQuery(['add-seats'], () => postAddSeats(count), {
    enabled: !!count,
    retry: 3,
  });
};

import { useMediaQuery } from '@mui/material';

export const useResponsive = () => {
  const isClient = useMediaQuery(`(min-width: 0px)`);
  const isMinMobile = useMediaQuery(`(max-width: 420px)`);
  const isMobile = useMediaQuery(`(max-width: 679px)`);

  const isTablet = useMediaQuery(`(max-width:1320px)`);

  const responsiveCSS = <T, M>(nonMobile: T, mobile: M) => {
    if (isMobile) return mobile;
    else return nonMobile;
  };

  const onClose = () => {
    if (isMobile) {
      history.back();
    } else {
      window.close();
    }
  };
  return { isClient, isMobile, isMinMobile, isTablet, responsiveCSS, onClose };
};

import { useResponsive } from '../hooks';
import { Stack } from '@mui/material';
import { Button, Ricon, theme, Typography } from '@imago-cloud/design-system';
import React from 'react';

export const UnknownError = ({ reset }: { reset: () => void }) => {
  const { responsiveCSS } = useResponsive();
  return (
    <Stack sx={{ alignItems: 'center', mt: '50%' }}>
      <Ricon icon="ri-alert-fill" svgProps={{ width: '80', height: '80', fill: theme.palette.error.main }} />
      <Typography variant={responsiveCSS('H24', 'H18')} sx={{ mt: '12px', mb: '20px', textAlign: 'center' }}>
        An Unknown error has occurred.
      </Typography>
      <Button size={responsiveCSS('36', '48')} variant="outlined" color="grey" onClick={reset}>
        Refresh
      </Button>
    </Stack>
  );
};

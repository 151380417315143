import React, { lazy, Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import {
  ACCESS_DENIED_PAGE_URL,
  CHECKOUT_LOADING_PAGE_URL,
  CHECKOUT_SUCCESS_PAGE_URL,
  COUNTRY_RESTRICTION_PAGE_URL,
  NETWORK_ERROR_PAGE_URL,
  PAYMENT_FAILED_PAGE_URL,
  PAYMENT_SUCCESS_PAGE_URL,
  PAYMENT_WALL_PAGE_URL,
  ROOT_PAGE_URL,
  SUCCESS_WAIT_PAGE_URL,
  UNAVAILABLE_LOCATION_PAGE_URL,
} from './constants/pageUrls';
import AccessDeniedPage from './page/AccessDeniedPage';
import { SuccessWaitPage } from './page/SuccessWaitPage';
import NetworkErrorPage from './page/NetworkErrorPage';
import CustomLoading from './compositions/CustomLoading';
import PaymentSelectionPage from './page/PaymentSelectionPage';
import ErrorBoundary from './components/ErrorBoundary';

const Router = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<CustomLoading />}>
        <Routes>
          <Route element={<Outlet />}>
            <Route path={ROOT_PAGE_URL} element={<PaymentSelectionPage />} />
            <Route path={PAYMENT_WALL_PAGE_URL} element={<PaymentWallPage />} />
            <Route path={PAYMENT_SUCCESS_PAGE_URL} element={<PaymentSuccessPage />} />
            <Route path={PAYMENT_FAILED_PAGE_URL} element={<PaymentFailedPage />} />
            <Route path={CHECKOUT_LOADING_PAGE_URL} element={<CheckoutLoadingPage />} />
            <Route path={CHECKOUT_SUCCESS_PAGE_URL} element={<CheckoutSuccessPage />} />
          </Route>
          {/* Without AuthHoc */}
          <Route path={SUCCESS_WAIT_PAGE_URL} element={<SuccessWaitPage />} />
          <Route path={ACCESS_DENIED_PAGE_URL} element={<AccessDeniedPage />} />
          <Route path={NETWORK_ERROR_PAGE_URL} element={<NetworkErrorPage />} />
          <Route path={UNAVAILABLE_LOCATION_PAGE_URL} element={<UnavailableLocationPage />} />
          <Route path={COUNTRY_RESTRICTION_PAGE_URL} element={<CountryRestrictionPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Router;
const PaymentWallPage = lazy(() => import('./page/PaymentWallIframePage'));
const PaymentSuccessPage = lazy(() => import('./page/PaymentSuccessPage'));
const PaymentFailedPage = lazy(() => import('./page/PaymentFailedPage'));
const CheckoutLoadingPage = lazy(() => import('./page/CheckoutLoadingPage'));
const CheckoutSuccessPage = lazy(() => import('./page/CheckoutSuccessPage'));
const UnavailableLocationPage = lazy(() => import('./page/UnavailableLocationPage'));
const CountryRestrictionPage = lazy(() => import('./page/CountryRestrictionPage'));

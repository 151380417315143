import React, { Component, ErrorInfo, ReactNode } from 'react';
import { UnknownError } from './UnknownError';
import { QueryErrorResetBoundary } from '@tanstack/react-query';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }
  resetErrorBoundary = (resetQuery: () => void) => {
    this.setState({ hasError: false, error: null });
    resetQuery();
  };

  render() {
    if (this.state.hasError) {
      return (
        <QueryErrorResetBoundary>
          {({ reset }) => <UnknownError reset={() => this.resetErrorBoundary(reset)} />}
        </QueryErrorResetBoundary>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
